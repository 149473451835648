@import url('https://cdn.syncfusion.com/ej2/material.css');

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}

.add-to-home-screen-pwa {
    display: table;
    position: fixed;
    text-align: center;
    z-index: 1000;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 10px;
    width: 100%;
    top: 0;
    box-shadow: 0 -4px 4px rgb(0 0 0 / 4%);
    border: 1px solid #cecece;
    cursor: pointer;
    color: #000;
}
.add-to-home-screen-pwa__notify button {
  display: inline-block;
  background-color: #BBF7D0 !important;
  color: #000000 !important;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 8px;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.1em;
  margin-top: 15px;
  padding: 1em 2.134em !important;
}